import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { isNil } from "lodash";
import React, { ReactElement, useEffect, useState } from "react";

import "./Checkbox.scss";
import Tooltip from "../Tooltip";

export type CheckboxProps = {
    id?: string;
    checked?: boolean;
    label?: string | React.ReactNode;
    disabled?: boolean;
    containerClassName?: string;
    tooltip?: string | ReactElement<any>;
    onChecked?: (checked: boolean) => void;
};

const Checkbox = ({ id, checked = false, label, tooltip, disabled, containerClassName, onChecked }: CheckboxProps) => {
    const [isChecked, setIsChecked] = useState<boolean>(checked);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    const handleChecked = e => {
        if (disabled) {
            return;
        }

        e.stopPropagation();

        const next = !isChecked;

        setIsChecked(next);

        if (onChecked) {
            onChecked(next);
        }
    };

    return (
        <div
            id={id}
            className={clsx("erp-checkbox-container", containerClassName, { disabled: disabled === true })}
            onClick={handleChecked}
        >
            <div className="erp-checkbox">{isChecked && <FontAwesomeIcon icon={faCheck} />}</div>

            <Tooltip tooltipContent={tooltip} disabled={isNil(tooltip)}>
                {!isNil(label) && <div className="erp-checkbox-label">{label}</div>}
            </Tooltip>
        </div>
    );
};

export default Checkbox;
