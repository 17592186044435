import { RunPeriodModel, TaskModel } from "./models";

export interface PluginSchema {
    id: PluginId;
    name: string;
    version: string;
    definitions: PluginDefinitions;
    assessments: PluginDefinitions;
    definition_groups: string[];
    assessment_groups: string[];
    features: PluginFeatures;
}

export type PluginDefinitions = Record<string, PluginDefinition>;

export interface PluginDefinition {
    id: string;
    name: string;
    scopes: PluginScopes[];
    fields: PluginField[];
    control?: PluginDefinitionControlTypes;
}

export interface PluginField {
    id: string;
    name: string;
    required: boolean;
    type: PluginFieldTypes;
    control: PluginControl;
}

export interface PluginControl {
    type: PluginControlTypes;
    unit?: string;
    range?: FieldRange;
    values?: string[] | number[] | PluginDropdownValue[];
    tooltip?: string;
    options?:
        | SeasonSelectOptions
        | CurveOptions
        | FlowTypeOptions
        | RiskCategoriesOptions
        | CheckboxInputOptions
        | SeasonTypeOptions
        | SpellTypeOptions;
    multi?: boolean;
}

export interface PluginDropdownValue {
    label: string;
    value: string;
}

export interface FieldRange {
    min: number;
    max: number;
}

export enum PluginScopes {
    MODEL = "model",
    NODE = "node",
    SITE = "site"
}

export enum PluginDefinitionControlTypes {
    WATERHOLE_OPTIMISATION = "waterhole_optimisation",
    SITE_DATA = "site_data"
}

export enum PluginControlTypes {
    TEXT_INPUT = "text_input",
    NUMERIC_INPUT = "numeric_input",
    COMBOBOX = "combobox",
    CHECKBOX = "checkbox",
    DROPDOWN = "dropdown",
    MONTH_SELECT = "month_select",
    SEASON_SELECT = "season_select",
    ARI = "ari",
    CURVE = "curve",
    PARTIAL_TABLE = "partial_table",
    RISK_CATEGORIES = "risk_categories",
    PREDICTORS_SELECT = "predictors_select",
    TEMPORAL = "temporal_agg",
    TEMPORAL_FAILURE = "temporal_agg_failure",
    YEARLY_AGG = "yearly_agg",
    YEARLY_AGG_FAILURE = "yearly_agg_failure",
    YEARLY_CONVERSION = "yearly_conversion",
    DAILY = "daily_agg",
    DAILY_FAILURE = "daily_agg_failure",
    FLOW_TYPE = "flow_type",
    SEASON_TYPE = "season_type",
    VARIABLE_SEASON = "variable_season",
    CHECKBOX_INPUT = "checkbox_input",
    SPELL_TYPE = "spell_type",
    NUMERIC_RANGE = "numeric_range",
    VELOCITY_CALCULATION = "velocity_calculation",
    VELOCITY_ADJUSTMENT = "velocity_adjustment"
}

export enum PluginFieldTypes {
    NUMBER = "number",
    FLOAT = "float",
    BOOLEAN = "boolean",
    ARRAY = "array",
    MONTH = "month",
    SEASON = "season",
    STRING = "string",
    ARI = "ari",
    CURVE = "curve",
    RISK_CATEGORIES = "risk_categories",
    PREDICTORS = "predictors",
    FLOW_TYPE = "flow_type",
    SEASON_TYPE = "season_type",
    VARIABLE_SEASON = "variable_season",
    NUMERIC_RANGE = "numeric_range",
    VELOCITY_CALCULATION = "velocity_calculation",
    VELOCITY_ADJUSTMENT = "velocity_adjustment"
}

export enum ARITypes {
    THRESHOLD = "threshold",
    FLOW = "flow"
}

export enum NumericRangeTypes {
    NUMBER = "number",
    FLOAT = "float"
}

export enum SeasonTypeTypes {
    DATE = "date",
    TEMPERATURE = "temperature"
}

export enum FlowTypeTypes {
    MEDIAN = "median",
    THRESHOLD = "threshold"
}

export enum CriteriaTypes {
    THRESHOLD = "threshold",
    MEDIAN = "median"
}

export enum ComparisonType {
    GREATER_EQUAL = "gte",
    LESS_EQUAL = "lte"
}

export enum Month {
    JANUARY = "january",
    FEBRUARY = "february",
    MARCH = "march",
    APRIL = "april",
    MAY = "may",
    JUNE = "june",
    JULY = "july",
    AUGUST = "august",
    SEPTEMBER = "september",
    OCTOBER = "october",
    NOVEMBER = "november",
    DECEMBER = "december"
}

export enum SpellTypeTypes {
    ABOVE_THRESHOLD = "above_threshold",
    BELOW_THRESHOLD = "below_threshold",
    RANGE = "range"
}

export enum VelocityCalculationType {
    WIDTH_ROUGHNESS = "width_roughness",
    CROSS_SECTION = "cross_section"
}

export interface PluginFeatures {
    use_site: boolean;
}

export interface BaseParameter {
    [key: string]: Record<string, PluginFieldValue>;
}

export type PluginFieldValue =
    | string
    | number
    | boolean
    | Record<string, string | number | boolean>
    | PluginFieldArrayValue
    | RunPeriodModel;

export type PluginFieldArrayValue = Array<number | number[] | string>;

export type PluginParameters = Record<string, Record<string, PluginFieldValue>>;

export interface ParameterUpdateResult {
    parameters: PluginParameters;
    version: string;
    success: boolean;
    failure: PluginValidationFailure;
}

export interface SetPluginResult {
    parameters: PluginParameters;
    version: string;
}

export interface ComputationResult {
    tasks: TaskModel[];
    success: boolean;
    failure: ComputationFailure;
}

export interface OptimisationComputationResult {
    task: TaskModel;
    success: boolean;
    failure: ComputationFailure;
}

export interface DataDownloadInfo {
    name: string;
    blobId: string;
}

interface ComputationFailure {
    code: string;
    message: string;
}

interface PluginValidationFailure {
    code: string;
    message: string;
}

export interface SeasonSelectOptions {
    day: boolean;
    month: boolean;
    year: boolean;
}

export interface SpellTypeOptions {
    allow_ari: boolean;
    threshold_unit?: string;
    allow_season_cutoff?: boolean;
}

export interface FlowTypeOptions {
    title: string;
}

export interface SeasonTypeOptions {
    allow_partial_seasons: boolean;
    allow_temperature_comparison: boolean;
    allow_temperature_moving_average: boolean;
}

export interface RiskCategoriesOptions {
    high_label: string;
    low_label: string;
}

export interface CurveOptions {
    x_label: string;
    y_label: string;
    disable_add?: boolean;
    disable_remove?: boolean;
    disable_x?: boolean;
    disable_y?: boolean;
    allow_import?: boolean;
}

export interface CheckboxInputOptions {
    checkbox_label: string;
}

export interface ARIThresholdSettings {
    type: ARITypes;
    value: number;
    use_default_data: boolean;
}

export enum PluginId {
    AMBASSIS_AGASSIZII = "ambassis_agassizii",
    BANANA_PRAWN_GROWTH = "banana_prawn_growth",
    BARRAMUNDI_GROWTH = "barramundi_growth",
    BARRAMUNDI_JUVENILE_RECRUITMENT = "barramundi_juvenile_recruitment",
    BARRAMUNDI_YEAR_CLASS_STRENGTH = "barramundi_year_class_strength",
    BASS = "bass",
    CARP_RECRUITMENT = "carp_recruitment",
    MARY_RIVER_TURTLES = "mary_river_turtles",
    FISH_BARRIERS_AND_CONNECTIVITY = "fish_barriers_and_connectivity",
    FISH_RESILIENCE = "fish_resilience",
    FRESHES_PARTIAL_SEASON = "freshes_partial_season",
    KING_THREADFIN_SALMON = "king_threadfin_salmon",
    LOW_FLOW_SPAWNING_FISH = "low_flow_spawning_fish",
    LOWFLOW_PARTIAL_SEASON = "lowflow_partial_season",
    MACHROPHYTE_GROWTH = "machrophyte_growth",
    MARY_RIVER_COD = "mary_river_cod",
    MULTIYEAR_FRESHES_PARTIAL_SEASON = "multiyear_freshes_partial_season",
    NORTHERN_SNAKE_NECKED_TURTLE = "northern_snake_necked_turtle",
    OFFSHORE_REEF_FISHERY = "offshore_reef_fishery",
    OVERSUPPLY_PARTIAL_SEASON = "oversupply_partial_season",
    RATING_CURVE = "rating_curve",
    SUMMARY = "summary",
    TANDANUS = "tandanus",
    TURTLE = "turtle",
    WATERHOLE = "waterhole",
    SPELL_ANALYSIS = "spell_analysis",
    SIMULTANEOUS_SPELL_ANALYSIS = "simultaneous_spell_analysis",
    COLWELLS_INDEX = "colwells_index",
    BASEFLOW_SEPARATION = "baseflow_separation",
    AUSTRALIAN_BASS = "australian_bass",
    RIFFLES = "riffles"
}

export interface ApiResultFailure {
    code: string;
    message: string;
}

export interface ApiResult<T> {
    data: T;
    success: boolean;
    failure: ApiResultFailure;
}
