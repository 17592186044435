import React, { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { PluginDropdownValue, SpellTypeTypes, ARIThresholdSettings, SpellTypeOptions } from "../../types/plugin";
import { PluginFieldProps, PluginForm } from "./types";
import Select, { SelectOption } from "../../Components/Select/Select";
import PluginParameterARIThreshold from "./PluginParameterARIThreshold";
import Checkbox from "../Checkbox";

interface PluginParameterSpellTypeProps extends PluginFieldProps {
    onChange?: (field: string, value: Record<string, string | number | boolean>) => void;
}

const spellTypeValues: PluginDropdownValue[] = [
    { label: "Above Threshold", value: SpellTypeTypes.ABOVE_THRESHOLD },
    { label: "Below Threshold", value: SpellTypeTypes.BELOW_THRESHOLD },
    { label: "Range", value: SpellTypeTypes.RANGE }
];

const PluginParameterSpellType = ({
    id,
    definition,
    field,
    control,
    disabled,
    onChange
}: PluginParameterSpellTypeProps) => {
    const { watch, getValues } = useFormContext<PluginForm>();

    const [watchedValues] = watch([`${definition.id}.${field.id}`]);

    const options: SpellTypeOptions = field.control.options as SpellTypeOptions;

    const singleThreshold =
        watchedValues?.type === SpellTypeTypes.ABOVE_THRESHOLD ||
        watchedValues?.type === SpellTypeTypes.BELOW_THRESHOLD;

    const upperThresholdLabel = singleThreshold ? "Threshold" : "Upper Threshold";

    const handleSpellTypeTypeChanged = (type: SpellTypeTypes) => {
        const currentSpellType = getValues(`${definition.id}.${field.id}`);

        onChange("spell_type", {
            ...currentSpellType,
            type: type
        });
    };

    const handleUpperThresholdChanged = (thresholdSettings: ARIThresholdSettings) => {
        const currentSpellType = getValues(`${definition.id}.${field.id}`);

        const nextSpellType = {
            ...currentSpellType,
            upper_threshold_type: thresholdSettings.type,
            upper_threshold_value: thresholdSettings.value,
            upper_threshold_use_default_data: thresholdSettings.use_default_data
        };

        onChange("spell_type", nextSpellType);
    };

    const handleLowerThresholdChanged = (thresholdSettings: ARIThresholdSettings) => {
        const currentSpellType = getValues(`${definition.id}.${field.id}`);

        const nextSpellType = {
            ...currentSpellType,
            lower_threshold_type: thresholdSettings.type,
            lower_threshold_value: thresholdSettings.value,
            lower_threshold_use_default_data: thresholdSettings.use_default_data
        };

        onChange("spell_type", nextSpellType);
    };

    const upperThresholdSettings: ARIThresholdSettings = useMemo(() => {
        return {
            type: watchedValues?.upper_threshold_type,
            value: watchedValues?.upper_threshold_value,
            use_default_data: watchedValues?.upper_threshold_use_default_data
        };
    }, [
        watchedValues?.upper_threshold_type,
        watchedValues?.upper_threshold_value,
        watchedValues?.upper_threshold_use_default_data
    ]);

    const lowerThresholdSettings: ARIThresholdSettings = useMemo(() => {
        return {
            type: watchedValues?.lower_threshold_type,
            value: watchedValues?.lower_threshold_value,
            use_default_data: watchedValues?.lower_threshold_use_default_data
        };
    }, [
        watchedValues?.lower_threshold_type,
        watchedValues?.lower_threshold_value,
        watchedValues?.lower_threshold_use_default_data
    ]);

    const handleCutoffOutsideSeasonChanged = (cutoffOutsideSeason: boolean) => {
        const currentSpellType = getValues(`${definition.id}.${field.id}`);

        onChange("spell_type", {
            ...currentSpellType,
            cutoff_outside_season: cutoffOutsideSeason
        });
    };

    return (
        <div className="spell-type-parameter-field">
            <Controller
                control={control}
                name={`${definition.id}.${field.id}.type`}
                render={({ field: _field }) => {
                    return (
                        <div className="spell-type-select">
                            <Select
                                id={id}
                                labelText={field.name}
                                labelClassName="plugin-definition-field-label"
                                value={spellTypeValues.find(v => v.value === _field.value)}
                                values={spellTypeValues}
                                onSelected={(option: SelectOption) => {
                                    handleSpellTypeTypeChanged(option.value as SpellTypeTypes);
                                }}
                                isClearable={false}
                                isSearchable={false}
                                disabled={disabled}
                            />
                        </div>
                    );
                }}
            />

            <PluginParameterARIThreshold
                thresholdSettings={upperThresholdSettings}
                allowAri={options.allow_ari}
                thresholdUnit={options.threshold_unit}
                label={upperThresholdLabel}
                disabled={disabled}
                onChange={handleUpperThresholdChanged}
            />

            {!singleThreshold && (
                <PluginParameterARIThreshold
                    thresholdSettings={lowerThresholdSettings}
                    allowAri={options.allow_ari}
                    thresholdUnit={options.threshold_unit}
                    label="Lower Threshold"
                    disabled={disabled}
                    onChange={handleLowerThresholdChanged}
                />
            )}

            {options?.allow_season_cutoff && (
                <Controller
                    control={control}
                    name={`${definition.id}.${field.id}.cutoff_outside_season`}
                    render={({ field: _field }) => {
                        return (
                            <Checkbox
                                id={id}
                                containerClassName="season-cutoff-checkbox"
                                label="Restrict analysis to only within season"
                                tooltip="If disabled an event that begins within the season can continue after the season ends"
                                checked={_field.value}
                                disabled={disabled}
                                onChecked={(checked: boolean) => {
                                    if (checked !== _field.value) {
                                        handleCutoffOutsideSeasonChanged(checked);
                                    }
                                }}
                            />
                        );
                    }}
                />
            )}
        </div>
    );
};

export default PluginParameterSpellType;
